<template>
  <v-container fluid>
    <v-row>
      <v-subheader class="px-0">Minha Conta > Contratos de Serviço</v-subheader>
      <v-card width="100%">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>

        <v-col class="k-list pa-2">
          <v-progress-linear
            class="k-loading-line"
            :indeterminate="loading"
            height="2"
            color="green darken-3"
          ></v-progress-linear>
          <v-simple-table>
            <tbody>
              <tr>
                <td>Contrato de Prestação de Serviços</td>
                <td>
                  <v-btn @click="openUseTerms()" icon class="k-right">
                    <v-icon color="grey darken-2" medium>visibility</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>Política de Privacidade</td>
                <td>
                  <v-btn
                    @click="dialogsChangePrivacePolice(true)"
                    icon
                    class="k-right"
                  >
                    <v-icon color="grey darken-2" medium>visibility</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-card>
    </v-row>
    <dialog-terms v-model="dialogsChangeUseTerms"></dialog-terms>
    <dialog-privace-police></dialog-privace-police>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const dialogTerms = () => import('@src/components/dialogs/dialog-use-terms.vue')
const dialogPrivacePolice = () =>
  import('@src/components/dialogs/dialog-privace-police.vue')

export default {
  name: 'Contract',
  components: {
    dialogTerms,
    dialogPrivacePolice,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      headerTitle: 'Contratos de Serviço',
      headerDescription: `Nesta área você poderá consultar, sempre que quiser, os contratos que
      aceitou por meio da nossa plataforma ao se tornar um cliente do
      Simplificador.`,
      dialogsChangeUseTerms: false,
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/company',
    }),
  },
  methods: {
    ...mapActions({
      dialogsChangePrivacePolice: 'dialogs/changePrivacePolice',
      getPartners: 'partner/getAllPartners',
    }),
    openUseTerms() {
      this.getPartners().then(() => {
        this.dialogsChangeUseTerms = true
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.k-list
  ul
    list-style-type: none
    padding: 0

    li
      border-bottom: 1px solid gray

.k-loading-line
  margin: 0
</style>
